import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
  Toast,
  Modal,
  FormSelect
} from 'react-bootstrap'

import { useForm } from 'react-hook-form'
import { BASE } from '../../basedata/constants'
import './visitorCreate.sass'
import Swal from 'sweetalert2'


import 'react-datepicker/dist/react-datepicker.css'
import withReactContent from 'sweetalert2-react-content'


const MySwal = withReactContent(Swal)


interface Props {
  terminalData: any
}

export function TerminalListItem({ terminalData}:  Props) {
  


const [currentTerminal, setCurrentTerminal] = useState<any>({})

const [show, setShow] = useState(false);

const handleClose = () => {setShow(false);  setCurrentTerminal({})};
const handleShow = () => setShow(true);

const [disableSign, setDisableSign] = useState(false)

const [showConfirm, setShowConfirm] = useState(false);

const handleShowConfirm = () => setShowConfirm(true)
const handleCloseConfirm = () => setShowConfirm(false)

const {
  register,
  handleSubmit,
  setError,
  formState: { errors },
  clearErrors,
  getValues,
  reset
} = useForm()

  const editCoordinator = (data: any) => {

    fetch(`${BASE.api_flamengo.base_url}${BASE.api.terminals}/${terminalData.id}`, {
        method: 'Put',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': BASE.api.api_key
        },
        body: JSON.stringify({
          ...data,
          isActive: (data.isActive === 'true') ? true:false,
          status: (data.isActive === 'true') ? 'active':'inactive'
        })
    })
        .then((response) => {
            window.location.reload();
        })
        .catch((error) => {
            console.log(error)
        })
  }

  
  const modalOpen = (item:any) => {
    setCurrentTerminal({})
  
    setTimeout(() => {
      setCurrentTerminal(item)
      handleShow()
    }, 300);
  }
  

  return (
    <>

<tr onClick={() => {modalOpen(terminalData);}}>
  <td>{terminalData.beboxIP}</td>
  <td>{terminalData.deviceIP}</td>
  <td>{terminalData.beboxMacAddress}</td>
  <td>{terminalData.deviceName}</td>
  <td>{terminalData.gate}</td>
  <td>{terminalData.sector}</td>
  <td className='text-center'>{terminalData.deviceBrand}</td>
  <td>{terminalData.isActive ? 'Ativo':'Inativo'}</td>
</tr>

    <Modal show={show} onHide={handleClose}>

<Modal.Body>
<Form onSubmit={handleSubmit(editCoordinator)}>
    <Row className="mb-3">
      <Col sm={12} xl={6}>
        <Form.Label>Digite o IP da catraca</Form.Label>
        <Form.Control
          type="text"
          placeholder="Digite o IP da catraca"
          {...register('beboxIP')}
          defaultValue={currentTerminal.beboxIP}
        />
      </Col>

      <Col sm={12} xl={6}>
        <Form.Label>Status</Form.Label>
        <FormSelect
          className="form-control"
          {...register('isActive')}
          defaultValue={currentTerminal.isActive ? 'true':'false'}
        >
          <option value="true">Ativo</option>
          <option value="false">Invativo</option>
        </FormSelect>

      </Col>
    </Row>


        <Row className="mb-3">
          <Col sm={12} xl={6}>
            <Form.Label>Digite o MacAddress</Form.Label>
            <Form.Control placeholder="Mac Address " {...register('beboxMacAddress')} defaultValue={currentTerminal.beboxMacAddress} />
          </Col>
          <Col sm={12} xl={6}>
            <Form.Label>Tag da Catraca</Form.Label>
            <Form.Control placeholder="Tag" {...register('deviceName')} defaultValue={currentTerminal.deviceName} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} xl={6}>
            <Form.Label>Portão</Form.Label>
            <Form.Control
              placeholder="Portão"
              {...register('gate')}
              defaultValue={currentTerminal.gate}
            />
            {errors.email && (
              <span className="label-error">
              </span>
            )}
          </Col>
          <Col sm={12} xl={6}>
            <Form.Label>Setor</Form.Label>
            <Form.Control
              type="text"
              placeholder="Setor"
              {...register('sector')}
              defaultValue={currentTerminal.sector}
            />
          </Col>
        </Row>


        <Row className="mb-3">
          <Col sm={12} xl={6}>
            <Form.Label>IP Dispositivo Facial</Form.Label>
            <Form.Control
              placeholder="IP Facial"
              {...register('deviceIP')}
              defaultValue={currentTerminal.deviceIP}
            />
          </Col>
          <Col sm={12} xl={6}>
            <Form.Label>Marca Dispositivo Facial</Form.Label>
            <Form.Control
              type="text"
              placeholder="intelbras | hikvision"
              {...register('deviceBrand')}
              defaultValue={currentTerminal.deviceBrand}
            />
          </Col>
        </Row>


      <Row>
        <Col sm={12} xl={6}>
          <Form.Group>
            <Button
              disabled={disableSign}
              type="submit"
              className="mb-0 w-100"
              onClick={() => clearErrors()}
            >
              Salvar
            </Button>
          </Form.Group>
        </Col>
      </Row>

  </Form>

</Modal.Body>

  </Modal>
    </>
  )
}
